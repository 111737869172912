jQuery(document).ready(($) => {

    var response,
        list        = $('.ajax-load-items'),
        loadResults = $('.load-search-results'),
        searchInput = $('.ajax-get-search');

    function displayList() {
        if (loadResults.hasClass('d-none')) {
            loadResults.fadeIn(100).removeClass('d-none')
        }
    }

    function hideList() {
        if (! loadResults.hasClass('d-none')) {
            $('.load-term').remove();
            loadResults.fadeOut(100).addClass('d-none')
        }
    }

    $('#searchform').on('submit', function(){
        loadResults.fadeOut(100).addClass('d-none')
    });

    // Action
    searchInput.on('keyup, input', function () {

        var keyWord = $(this).val();

        // Reseta o timer da requisição anterior;
        clearTimeout($.data(this, 'timer'));

        hideList()

        // Se o campo de busca estiver vazio
        if (! keyWord || keyWord.length < 3) {
            $('.card-glossary-term').show();
            $('.ajax-load-card').hide();
        }

        //Se o campo de busca tiver 3 ou mais caracteres
        else {

            // Define um time para evitar requisições em excesso.
            // Caso não seja resetado em menos de .5 segundos, subentendesse
            // que o usuário terminou a digitação e realiza a requisição.
            $(this).data('timer', setTimeout(function(){
                $.post({
                    url: IntegracaoLiveSearch.ajax_url,
                    data: {
                        action: IntegracaoLiveSearch.action,
                        keyword: keyWord,
                    },
                    success: function (response) {

                        // Remove os itens consultados anteriormente
                        $('.list-item').remove()

                        // Se existirem itens na resposta, adiciona os novos itens a lista
                        if (response.data.length !== 0) {
                            displayList()
                            $.each(response.data, function(key, val) {
                                list.fadeIn().append('<li class="list-item load-term"><a href="' + val.link +'">' + val.title + '</a></li>')
                            })
                        } else {
                            displayList()
                            list.fadeIn().append('<li class="load-term">Não encontramos essa palavra. Sugira termos novos</li>')
                        }
                    },
                }) // ajax
            }, 600))
        }
    })
})